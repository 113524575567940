<template>
  <v-row class="mt-1 mb-1" justify="center">
    <v-col md="6">
      <v-card rounded>
        <v-card-title>Авторизация</v-card-title>
        <v-card-text class="black--text pb-1">
          <p>Войдите в свою учетную запись Талант, чтобы продолжить</p>
        </v-card-text>
        <v-card-actions class="px-4 pt-0 pb-4">
          <v-btn color="primary" class="px-4" :href="'/api/auth/redirect'"
            >Войти</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Login",
  metaInfo: {
    title: "Авторизация",
  },
};
</script>

<style></style>
